<template>
    <div class="array-scroll-wrapper">
        <div class="array-container">
            <div class="title-wrapper" :style="gridTemplateColumns">
                <div
                    v-for="(data, index) in content.header"
                    :key="index"
                    :class="
                        content.header[0] === 'Name' ? 'sticky-position' : ''
                    "
                >
                    <p>
                        {{ data.name }}
                    </p>
                    <i
                        v-if="data.action"
                        :style="[
                            data.show
                                ? 'visibility: visible;'
                                : 'visibility: hidden;',
                            data.style ? data.style : '',
                        ]"
                        :class="data.class ? data.class : ''"
                    ></i>
                </div>
            </div>
            <div class="content-wrapper">
                <div
                    v-for="(data, index) in content.content"
                    :key="`content-row-${index}`"
                    class="content-grid"
                    :style="gridTemplateColumns"
                >
                    <div
                        v-for="(value, key) in data"
                        :key="`content-cell-${index}-${key}`"
                        class="content"
                    >
                        <div v-if="Array.isArray(value)" class="value-wrapper">
                            <div
                                v-for="(item, itemIndex) in value"
                                :key="itemIndex"
                            >
                                <div
                                    class="value-icon-situation"
                                    v-if="item.option"
                                    @click="
                                        checkAction(
                                            item.option.action,
                                            item.option.id
                                        )
                                    "
                                    :style="
                                        item.option.action
                                            ? 'cursor: pointer;'
                                            : 'cursor: default;'
                                    "
                                >
                                    <p>{{ item.value }}</p>
                                    <i :class="item.option.icon.class"></i>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            :style="
                                value.action && value.show
                                    ? 'cursor: pointer;'
                                    : 'cursor: default;'
                            "
                            class="normal-value-wrapper"
                        >
                            <p v-if="key !== 'option' && key !== 'id'">
                                {{ value }}
                            </p>
                            <i
                                v-if="
                                    key === 'option' && value.icon && value.show
                                "
                                :class="value.icon.class"
                                :style="value.icon.style"
                                @click="checkAction(value.action, value.id)"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: [Array, Object],
            default: () => [],
        },
        // How to format content prop:

        // content:{
        //     header: [
        //         {
        //             name: Name of your column,
        //             show: If you want to show the title of column,
        //             size: Column size compared to a Grid configuration(default : 1fr),
        //             style: If you want to add a style to the column,
        //             class: If you want to add a class to the column(principally for icons),
        //             action: If you want to add an action to the column (actuellement "seeMore" and "delete", add more in switch case below),
        //         }
        //     ]
        //     content: [
        //         {
        //             If you have just 1 data to show (e.g. name of a building):
        //             keyOfYourChoice: value,

        //             If you have text and an icon to show(e.g.name of a building with an eye icon ):
        //             keyOfYourChoice: [{
        //                 value: value of "value" key,
        //                 option: {
        //                     action: name of your action (actuellement "seeMore" and "delete", add more in switch case below),
        //                     id: id for the action,
        //                     icon: {
        //                         class: icon class,
        //                         style: icon style,
        //                     },
        //                     show: If you want to show the icon,
        //                 }
        //             }],

        //             If you want to add an action to the cell with no text (actuellement "seeMore" and "delete", add more in switch case below):
        //             option: {
        //                 action: name of your action,
        //                 id: id for the action,
        //                 icon: {
        //                     class: icon class,
        //                     style: icon style,
        //                 },
        //                 show: If you want to show the icon,
        //             },
        //         }
        //     ]
        // }
    },
    emits: ["deleteItem", "seeMore"],
    computed: {
        processedContent() {
            if (Array.isArray(this.content.header)) {
                return this.content.header
            } else if (typeof this.content.header === "object") {
                return Object.values(this.content.header)
            } else {
                return []
            }
        },
        gridTemplateColumns() {
            const columnSizes = this.processedContent.map((t) =>
                t && t.size ? t.size : "1fr"
            )

            const style = this.processedContent.map((t) =>
                t.style ? t.style : ""
            )

            return `grid-template-columns: ${columnSizes.join(" ")}; ${style}`
        },
    },
    methods: {
        checkAction(action, id) {
            switch (action) {
                case "delete":
                    this.$emit("deleteItem", id)
                    break
                case "seeMore":
                    this.$emit("seeMore", id)
                    break
                default:
                    break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";

.array-scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .array-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-width: max-content;

        .title-wrapper {
            display: grid;
            gap: 1em;
            width: 100%;
            padding: 1em 0.5em;
            border-bottom: 1px solid $grey-neutral;

            p {
                margin: 0 !important;
                font-family: $font_avenir_heavy;
                font-size: $normal;
            }
        }

        .content-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .content-grid {
                display: grid;
                gap: 1em;
                width: 100%;
                height: 100%;
                padding: 0em 0.5em;
                border-bottom: 1px solid $grey-neutral;
                transition: all 0.2s;

                &:hover {
                    background-color: $grey-light;
                    transition: all 0.2s;
                    // cursor: pointer;
                }

                .content {
                    z-index: 0;
                    word-break: break-word;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    p {
                        margin: 0 !important;
                        font-size: $normal;
                    }

                    i {
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    .normal-value-wrapper {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        padding: 1em 0em;
                    }

                    .value-wrapper {
                        width: 100%;
                        .value-icon-situation {
                            display: flex;
                            align-items: center;
                            padding: 1em 0em;
                            width: 100%;
                            gap: 1em;
                        }
                    }
                }
            }
        }
    }
}
</style>
